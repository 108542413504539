<template>
  <div class="welcome">
    <div class="fill">
      <div class="container">
        <el-row
          type="flex"
          justify="center"
        >
          <el-col
            :xl="14"
            :lg="18"
            :md="18"
            :sm="24"
            :xs="24"
          >
            <h1 class="text-white">
              Great, you're now part of the community
            </h1>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="center"
        >
          <el-col
            :xl="12"
            :lg="14"
            :md="18"
            :sm="24"
            :xs="24"
          >
            <el-form
              ref="form"
              v-loading="loading"
              :model="form"
              :rules="rules"
            >
              <span class="title">{{ brand.lang.welcomeInviteText }}</span>
              <el-row
                :gutter="20"
                class="d-flex"
              >
                <el-col
                  :xl="18"
                  :lg="18"
                  :md="18"
                  :sm="24"
                >
                  <el-form-item
                    label="Personal email address"
                    prop="email"
                  >
                    <el-input
                      v-model="form.email"
                      type="email"
                      placeholder="Your email"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col
                  :xl="6"
                  :lg="6"
                  :md="6"
                  :sm="24"
                  class="send-btn"
                >
                  <button
                    type="submit"
                    :disabled="loading"
                    class="fgh-button fgh-button--primary-outline"
                    @click.prevent="onSubmit"
                  >
                    Send
                  </button>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
        <!-- BUTTONS -->
        <el-row
          type="flex"
          justify="center"
        >
          <el-col
            :xl="10"
            :lg="14"
            :md="16"
            :sm="24"
            :xs="24"
          >
            <div class="question">
              <div class="title">
                <span>Where would you like to go next?</span>
              </div>
              <div class="buttons">
                <el-row
                  type="flex"
                  justify="center"
                  :gutter="15"
                >
                  <el-col
                    :xl="9"
                    :lg="9"
                    :md="9"
                    :sm="24"
                  >
                    <router-link :to="{ name: 'feed-feed' }">
                      <button class="fgh-button lg fgh-button--primary">
                        Show me my feed
                      </button>
                    </router-link>
                  </el-col>
                  <el-col
                    :xl="9"
                    :lg="9"
                    :md="9"
                    :sm="24"
                  >
                    <router-link to="/account">
                      <button class="fgh-button lg fgh-button--primary-grey-outline">
                        My profile
                      </button>
                    </router-link>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- BANNER -->
        <el-row
          type="flex"
          justify="center"
        >
          <el-col
            :xl="18"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
          >
            <div class="banner">
              <div class="left-side">
                <div class="img">
                  <img
                    src="/images/mobile.png"
                    srcset="/images/mobile@2x 2x.png"
                    alt="Image of FGH Mobile App"
                  />
                </div>
                <div class="txt">
                  <p>Get the app</p>
                </div>
              </div>
              <div class="right-side">
                <ul>
                  <li class="hidden-md-and-down">
                    <span>Download it from</span>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      :href="brand.appStoreLink"
                      style="margin-right: 15px"
                    >
                      <vsvg-icon
                        data="@assetsIcon/app-store-icon.svg"
                        scale="1"
                        original
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      :href="brand.playStoreLink"
                    >
                      <vsvg-icon
                        data="@assetsIcon/play-market-icon.svg"
                        scale="1"
                        original
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import authRules from '@/modules/auth/services/auth.rules'
import accountApi from '../../account/services/account.api'

export default {
  name: 'WelcomePage',

  data: () => ({ 
    loading: false,
    form: {
      email :''
    },
    rules: {
      email: [
        { validator: authRules.validateEmailAddress, required: true }
      ]
    }
  }),

  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          accountApi
            .invite(this.form.email)
            .then((response) => {
              this.loading = false
              this.$refs.form.resetFields()

              if (response.data.message !== '') {
                this.$notify.success({
                  title: 'Success',
                  message: response.data.message
                })
              }
            })
            .catch((error) => {
              this.loading = false
              this.$notify.error({
                title: 'Error',
                message: error.response.status == 422 ? error.response.data.errors.email[0] : 'An unexpected error occured. Please try again later'
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.welcome {
  height: calc(100vh - 80px);
  @include retina-background-image('/images/welcome', 'png', 100%, 100%);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  @media screen and (max-width: 375px) {
    h1 {
      font-size: 20px;
      width: 80%;
      text-align: center;
      margin: 20px auto;
    }
  }
  @media screen and (max-width: 420px) {
    height: 726px;
  }
  .fill {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    .container {
      padding: 0 15px;
    }
  }
  h1 {
    text-align: center;
    margin-top: 40px;
  }
  form {
    background-color: rgba(255,255,255,.9);
    padding: 21px 30px 28px 37px;
    border-radius: 25px;

    .el-loading-mask {
      background-color: rgba(255,255,255,.9);
      border-radius: 25px;
    }

    .el-form-item {
      margin-bottom: 0
    }
    label {
      color: $body-text-color;
      font-size: 12px;
      &:before {
        content: "";
        display: none;
      }
    }
    input {
      background: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $primary-color;
      padding: 0;
      font-size: 14px;
      color: $body-text-color;
    }
    @media screen and (max-width: 528px) {
      .d-flex {
        display: inherit
      }
    }
    .send-btn {
      position: relative;
      @media screen and (max-width: 528px) {
        text-align: center
      }
      button {
        position: absolute;
        bottom: -4px;
        padding: 13px 36px;
        @media screen and (max-width: 959px) {
          right: 0;
        }
        @media screen and (max-width: 528px) {
          position: relative;
        }
      }
    }
    .title {
      font-size: 16px
    }
  }
  .question {
    margin-top: 25px;
    color: $white-color;
    .title {
      text-align: center;
      font-size: 16px
    }
    .buttons {
      margin-top: 20px;
      text-align: center;
    }
      @media screen and (max-width: 425px) {
      .el-row {
        display: inherit
      }
      button:first-child {
        margin-bottom: 15px
      }
    }
    button {
      width: 100%;
      padding: 20px 0;
      border: none;
      @media screen and (max-width: 767px) {
        width: 70%;
      }
    }
  }
  .banner {
    position: relative;
    background-color: transparent;
    height: 80px;
    margin-top: 93px;
    @media (max-width: 425px) {
      margin-top: 63px;
      height: auto;
    }
      &:before {
      content: '';
      height: 0;
      border-top: 80px solid $primary-color;
      border-right: 100px solid transparent;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      @media (max-width: 667px) {
        display: none;
      }
    }
    &:after {
      content: '';
      width: 50%;
      height: 0;
      border-bottom: 80px solid $white-color;
      border-left: 100px solid transparent;
      border-top-right-radius: 4px;
      //border-bottom-right-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      @media (max-width: 667px) {
        display: none;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      @media (max-width: 667px) {
        display: inline-flex;
      }
    }
    .left-side {
      position: relative;
      left: 40px;
      bottom: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      @media (max-width: 667px) {
        background-color: $primary-color;
        left: 0;
        height: 78px;
      }
      img {
        margin-top: -29px;
      }
      p {
        color: $white-color;
        font-size: 16px;
      }
    }
    .right-side {
      position: absolute;
      right: 29px;
      top: 50%;
      margin-top: -13px;
      z-index: 2;
      @media (max-width: 768px) {
        right: 10px;
      }
      @media (max-width: 667px) {
        background-color: $white-color;
        position: relative;
        right: 0;
        top: 0;
        margin: 0;
        padding: 15px 0;
        text-align: center;
      }
      ul {
        li {
          margin-left: 12px;
          span {
            vertical-align: sub;
            font-size: 12px;
          }
        }
      }
    }
  }
  .container {
    padding: 0 15%;
  }
}
</style>
